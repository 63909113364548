import React from "react";
import { createShapeId, Tldraw } from "tldraw";
import { ExtendedContent } from "../CustomShapes/ExtendedContent";
import * as types from "constants/actionTypes";
import { useEffect } from "react";
import { useStore } from "context";
import { StoreTypes } from "context";
import { BookFlipType } from "constants/flipTypes";
import { ScrollButtonStateBehavior } from "components/ExtendedContent/ExtendedContentScrollPositionButton";
import { ScrollPositionButtonStateType } from "constants/scrollPositionType";

const ExtendedTldrawContainer = ({
  canvasHeight,
  canvasWidth,
  extendedContentType,
  content
}) => {
  const [, extendedContentDispatch] = useStore(StoreTypes.extendedContent);
  const [, tldrawDispatch] = useStore(StoreTypes.tldraw);
  const [{ books, bookId }] = useStore(StoreTypes.books);
  const book = books.find(item => item.bookId === bookId);
  const { LRFlip } = book;



  useEffect(() => {
    extendedContentDispatch({
      type: types.SET_EXTENDED_CONTENT_CHILD,
      payload: {
        extendedContentChild: content,
      }
    })
  }, [])

  return (
    <div
      style={{
        position: "absolute",
        inset: 0,
        width: canvasWidth,
        height: canvasHeight,
      }}
    >
      <Tldraw
        hideUi
        shapeUtils={[ExtendedContent]}
        components={{
          DebugPanel: false,
          ZoomMenu: false,
          NavigationPanel: false,
          PageMenu: false,
        }}
        onMount={(editor) => {
          const id = createShapeId(`extendedContent-${extendedContentType}`);
          editor.createShape({
            id,
            type: "extendedContent",
            props: { w: canvasWidth - 30, h: canvasHeight - 30 },
            meta: {
              bookFlipType: LRFlip
            },
            isLocked: true,
            x: 0,
            y: 0,
          });
          const RTLCameraOptions = {
            behavior: { x: "inside", y: "fixed" },
            initialZoom: "fit-y",
            baseZoom: "fit-y",
            origin: { x: 1, y: 0 },
          }
          const LTRCameraOptions = {
            behavior: { x: "fixed", y: "inside" },
            initialZoom: "fit-x",
            baseZoom: "fit-x",
            origin: { x: 0, y: 0 },
          }
          editor.setCameraOptions({
            isLocked: false,
            constraints: {
              // behavior: { x: "inside", y: "fixed" },
              bounds: { w: canvasWidth, h: canvasHeight, x: 0, y: 0 },
              padding: { x: 0, y: 0 },
              // origin: { x: 0, y: 0 },
              // initialZoom: "fit-y",
              // baseZoom: "fit-y",
              ...LRFlip === BookFlipType.RIGHT_TO_LEFT ? RTLCameraOptions : LTRCameraOptions
            },
            zoomSteps: [1, 2, 4, 8],
            wheelBehavior: "pan"
          });
          editor.setCamera(editor.getCamera(), { reset: true });
          
          editor.sideEffects.registerAfterChangeHandler(
            "camera",
            (prev, next) => {
              const camera = editor.getCamera();
              const cameraBounds = editor.getCameraOptions().constraints.bounds;
              const pageBounds = editor.getViewportPageBounds();

              if (LRFlip === BookFlipType.RIGHT_TO_LEFT) {
                if (
                  Math.ceil(pageBounds.w - cameraBounds.w) ===
                  Math.ceil(camera.x)
                ) {
                  ScrollButtonStateBehavior(ScrollPositionButtonStateType.Head);
                } else if (camera.x === 0) {
                  ScrollButtonStateBehavior(
                    ScrollPositionButtonStateType.Bottom
                  );
                } else {
                  ScrollButtonStateBehavior(
                    ScrollPositionButtonStateType.Middle
                  );
                }
              }

              if (LRFlip === BookFlipType.LEFT_TO_RIGHT) {
                if (camera.y === 0) {
                  ScrollButtonStateBehavior(ScrollPositionButtonStateType.Head);
                } else if (
                  Math.ceil(pageBounds.h - cameraBounds.h) ===
                  Math.ceil(camera.y)
                ) {
                  ScrollButtonStateBehavior(
                    ScrollPositionButtonStateType.Bottom
                  );
                } else {
                  ScrollButtonStateBehavior(
                    ScrollPositionButtonStateType.Middle
                  );
                }
              }
            }
          );

          tldrawDispatch({ type: "SET_EXTENDED_CONTENT_TLDRAW_EDITOR", payload: editor });
        }}
      >
      </Tldraw>
    </div>
  );
};

export default ExtendedTldrawContainer;
